import React from 'react';
import Img from 'gatsby-image';
import {
    MDBRow,
    MDBCol,
    MDBBtn,
}from 'mdbreact';
import { StaticQuery, graphql } from 'gatsby';

const About = () => {
    return(
        <StaticQuery
            query={graphql`
            query{
                about: 
                    file (relativePath: {eq: "About/aboutkels-3.jpg"})
                    {
                        id,
                        childImageSharp {
                            fluid(maxWidth:500, quality:72){
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }`
            }

            render={ data =>(
                    <div className='about-paper z-depth-2'>
                <MDBRow center>
                    <MDBCol xs='12' sm='6' md='4' middle>
                        <Img className='about-thumbnail rounded-circle img-fluid inline' fluid={data.about.childImageSharp.fluid}/>
                    </MDBCol>
                    <MDBCol xs='12' sm='10' md='7' middle>
                        <p>
                            Thanks for checking out my website! 
                            I’m Kelsey, a lifestyle & fitness model based in Hawai&#699;i. 
                            I love adventures, a good cup of local Kona coffee 
                            and animals (I even have my undergrad in Marine Science). 
                            One of my favorite aspects of modeling is that I get to
                            be part of an expression of ideas through an image. 
                            I have always been fascinated by the way photos can 
                            communicate to us without words. So grab your beverage
                            of choice (I’m gonna grab my coffee) and please allow 
                            me to take a minute to share with you some projects 
                            I’ve been blessed to have been a part of.
                        </p>
                    </MDBCol>
                    <MDBCol size='12' className='text-center'>
                        <MDBBtn href='/#portfolio' size='lg' color='light-blue'>Portfolio</MDBBtn>
                    </MDBCol>
                </MDBRow>
                    </div>
            )
            }
        />
    )
}

export default About;