import React from "react"
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import Gallery from '../components/Gallery';
import Layout from '../components/Layout'
import About from '../components/About';
import {
	MDBContainer, 
	} from 'mdbreact';

export default function Home({data}) {
	const portfolioThumbnails = data.portfolioThumb.edges
	const portfolioModal = data.portfolioModal.edges
	const description = "Kelsey Makida is a Hawai\u02BBi based lifestyle and fitness model."
	return (
		<>
		<Layout fancyHeader description={description}>
			<div>
				<Img style={{height:'100vh'}} fluid={data.landing.childImageSharp.fluid}/>
				{/* <Button size='lg' className='portfolio-button' href='#portfolio'>Portfolio</Button> */}
			</div>
			<section id='about'>
				<MDBContainer>
					<h2>Aloha! Nice to meet you!</h2>
					<About/>
				</MDBContainer>

			</section>
			<section id='portfolio' className='gallerySection'>
				<MDBContainer>
						<h2>Here are my favorites...</h2>
					<Gallery thumbnails={portfolioThumbnails}
						modalPhotos={portfolioModal} 
						btnHref='/gallery'
						btnText='See More'/>
				</MDBContainer>
			</section>
		</Layout>
		</>
	)
}

export const query = graphql`
	query {
		landing: 
			file (relativePath: {eq: "Landing/landing.jpg"})
			{
				id,
				childImageSharp {
					fluid(maxWidth:3840, quality:72){
						...GatsbyImageSharpFluid
					}
				}
			}
		,
		portfolioThumb: 
			allFile (filter:{relativePath: {regex: "/Portfolio/"}})
			{
				edges {
					node {
						id,
						childImageSharp{
							fluid(maxWidth:500 quality:72){
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
				}
			}
		portfolioModal: 
			allFile (filter:{relativePath: {regex: "/Portfolio/"}})
			{
				edges {
					node {
						id,
						childImageSharp{
							fluid(maxWidth:3840 quality:72){
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
				}
			}
		
	}`